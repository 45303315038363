<div class="flex justify-center">
    <div
        class="relative -ml-[24px] -mr-[75px] block w-[calc(100vw-60px)] transition-all xs:-ml-[35px] xs:-mr-[90px] md:-mx-[120px] md:w-[768px] lg:-mx-[80px] xl:-mx-48"
    >
        <ui-m000-c010-image
            class="block h-full"
            [image]="{
                image: car().image.url,
                desktop: { width: 850, height: 433 },
                tablet: { width: 768, height: 433 },
                mobile: { width: 768, height: 433 },
            }"
            [priority]="isFirstView()"
            [alt]="car().image.alt || car().label"
            classList="size-full aspect-[768/433] "
            contain
            highRes
            cover
        />

        @if (car().badge) {
            <ui-m040-c030-badge
                class="disable-on-not-active absolute left-48 top-16 block transition-opacity duration-500 xs:left-[59px] md:left-[100px] md:top-[50px]"
                type="blue"
            >
                {{ car().badge }}
            </ui-m040-c030-badge>
        }
    </div>
</div>
