<!-- container size -->
<div
    class="flex flex-col gap-x-24 lg:inner-page-wrapper md:grid md:grid-cols-2 md:py-24 lg:pb-[80px] lg:pt-40 xl:flex xl:flex-row xl:items-center xl:gap-x-56"
>
    <div class="relative h-full flex-grow xl:max-w-[474px]">
        <ui-m000-c010-image
            class="block h-full"
            [classList]="'h-full w-full aspect-square object-cover'"
            [image]="{
                image: image().url,
                desktop: { width: 474, height: 474 },
                tablet: { width: 488, height: 540 },
                mobile: { width: 767, height: 767 },
            }"
            [priority]="isFirstView()"
            [alt]="image().alt || (imageCaption() ?? text())"
            cover
        />
        @if (externalLink() && externalLinkText()) {
            <a
                ui-m010-c025-icon-link-external
                class="absolute bottom-0 left-0 hidden p-8 md:block"
                [hyundaiRouterLink]="externalLink()"
                white
            >
                {{ externalLinkText() }}
            </a>
        }
        @if (imageCaption(); as caption) {
            <p class="leading-21 mt-8 px-24 text-14 text-grey-400 lg:px-0">{{ caption }}</p>
        }
    </div>

    <div class="flex flex-col items-start justify-center px-24 py-40 md:p-0 xl:max-w-[686px]">
        <div class="font-headline text-24 font-medium leading-28 text-black md:text-34 md:leading-41">
            <ng-content select="[headline]" />
        </div>
        @let textFootnotes = text() | footnotes;
        <p class="mt-12 text-16 leading-27 text-black" [innerHTML]="textFootnotes()"></p>

        @if (envkv(); as data) {
            @if (data.envkv && data.model) {
                <div class="my-12 w-full border-y-1 border-grey-200 py-[28px] md:my-8 md:py-16">
                    <ui-m040-c010-envkv [data]="data" [color]="'black'" />
                </div>
            }
        }

        @if (shortInfos(); as data) {
            @if (data.length > 0) {
                <div
                    class="flex w-full gap-16 border-b-1 border-grey-200 pb-12 md:flex-col md:gap-x-40 md:pb-8 lg:flex-row"
                >
                    @for (item of data; track $index) {
                        <div class="lg:max-w-[200px]">
                            <div class="flex items-end md:mb-12">
                                <span
                                    class="mr-4 font-headline text-50 font-medium leading-58 text-black md:text-74 md:leading-78"
                                >
                                    {{ item.value }}
                                </span>
                                <span
                                    class="mb-6 inline-block font-headline text-18 leading-27 md:mb-8 md:text-20 md:leading-30"
                                >
                                    {{ item.unit }}
                                </span>
                            </div>
                            <p class="leading-21 text-14 text-black md:text-16 md:leading-27">{{ item.label }}</p>
                        </div>
                    }
                </div>
            }
        }

        @if (showOnlyPrimaryButton()) {
            <a
                class="button-primary mt-24 md:mt-16 lg:w-max"
                [hyundaiRouterLink]="link()"
                (click)="primaryButtonClicked.emit()"
            >
                {{ linkText() }}
            </a>
        } @else {
            <a
                ui-m010-c010-icon-double-cta
                class="mt-24 flex w-full justify-center md:mt-12 md:w-auto"
                [hyundaiRouterLink]="ctaLink()"
                (click)="ctaClicked.emit()"
            >
                {{ ctaText() }}
                <ng-container subtext>{{ ctaSubText() }}</ng-container>
            </a>

            <a
                ui-m010-c020-icon-link
                class="mt-24 inline-flex md:mt-16"
                [hyundaiRouterLink]="link()"
                (click)="linkClicked.emit()"
            >
                {{ linkText() }}
            </a>
        }

        @if (externalLink(); as link) {
            <a ui-m010-c025-icon-link-external class="mt-24 block md:hidden" [hyundaiRouterLink]="link">
                {{ link }}
            </a>
        }
    </div>
</div>
